import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Card from '../../components/Card';
import Button from '../../components/Button';
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from '../../context/GlobalContextProvider';
import { parseQuery } from '../../utils/parseQuery';
import { parseDate } from '../../utils/parseDate';
import InformationGrid from '../../components/InformationGrid';
import { do_url } from '../../utils/siteVars';
import { sanitizeFileNameForContentHeader } from '../../utils/sanitizeText';
import DotsDropdown from '../../components/DotsDropdown';
import OverlayBox from '../../components/OverlayBox';
import CustomerFilesBrowser from '../../components/CustomerFilesBrowser';
import FileUploadButton from '../../components/FileUploadButton';
import { progressRequest, request } from '../../utils/request';

const StyledCustomerPortalPage = styled.div`
  #customerPortalFileInput {
    display: none;
  }
  .actions-container {
    display: flex;
    flex-direction: column;
    button {
      display: block;
    }
  }
  .single-customer-file {
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 0.3rem;
    align-items: center;
  }
  .users-to-reset-list {
    margin-top: 2rem;
    margin-bottom: 2rem;
    .users-to-reset-item {
      display: flex;
      input {
        width: revert;
      }
    }
  }
  .customer-card-header-right-content {
    display: flex;
    gap: 1rem;
  }
`;

const CustomerPortalPage = ({ location }) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [customer, setCustomer] = useState();
  const [originalCustomer, setOriginalCustomer] = useState();
  const [filesList, setFilesList] = useState([]);
  const [filesListS3, setFilesListS3] = useState([]);
  const [customerFilesModal, setCustomerFilesModal] = useState({
    active: false,
    selectedFiles: [],
  });
  const [deleteFileModal, setDeleteFileModal] = useState({
    active: false,
    fileName: '',
    uuid: '',
  });
  const [informationGridData, setInformationGridData] = useState([
    { h: '', p: '', fw: true },
  ]);
  const [blockCustomerModal, setBlockCustomerModal] = useState(false);
  const [resetAccountModal, setResetAccountModal] = useState(false);
  const [deleteAllFilesModal, setDeleteAllFilesModal] = useState(false);
  const [selectUsersToResetModal, setSelectUsersToResetModal] = useState({
    active: false,
    users: [],
  });

  const getCustomer = async () => {
    const { id } = parseQuery(location);
    if (!id) return;

    const { data, err } = await request({
      state,
      dispatch,
      path: `portalCustomers/${id}`,
    });
    if (err) return;
    const selectUsersToResetModal = {
      active: false,
      users: data.portalCustomerUsers.map((u) => {
        return {
          email: u.email,
          selected: false,
        };
      }),
    };
    selectUsersToResetModal.users.splice(0, 0, {
      email: data.portalCustomer?.loginEmail,
      selected: false,
    });
    setSelectUsersToResetModal(selectUsersToResetModal);
    setOriginalCustomer(data.customer);
    const tempInformationGridData = [
      {
        h: 'Senaste inloggning:',
        p: data.portalCustomer?.latestLoginAt
          ? parseDate(data.portalCustomer?.latestLoginAt)
          : '-',
        fw: true,
      },
      {
        h: 'Senaste nedladdning:',
        p: data.portalCustomer?.latestDownloadAt
          ? parseDate(data.portalCustomer?.latestDownloadAt)
          : '-',
        fw: true,
      },
      {
        h: 'Filer raderas: ',
        p: data.portalCustomer?.deleteFilesAt
          ? parseDate(data.portalCustomer?.deleteFilesAt)
          : '-',
        fw: true,
      },
      {
        h: 'Blockeringsstatus:',
        p: data.portalCustomer?.suspended ? 'Blockerad' : 'Ej blockerad',
        fw: true,
      },
    ];

    if (data.portalCustomer?.loginEmail) {
      tempInformationGridData.push({
        h: 'E-post för inloggning',
        p: data.portalCustomer?.loginEmail,
        fw: true,
      });
    }
    data.portalCustomerUsers.forEach((user, i) => {
      if (i === 0 && !data.portalCustomer?.loginEmail) {
        tempInformationGridData.push({
          h: 'E-post för inloggning',
          p: user.email,
          fw: true,
        });
      } else {
        tempInformationGridData.push({ h: ' ', p: user.email, fw: true });
      }
    });
    setInformationGridData(tempInformationGridData);

    if (data.portalCustomer) {
      setCustomer(data.portalCustomer);
    } else {
      createInitialPortalCustomer(id, data.customer);
    }
    getFiles();
  };
  const resetCustomerAccount = async () => {
    const { id } = parseQuery(location);
    if (!id) return;
    const { err } = await request({
      state,
      dispatch,
      path: `portalCustomers/reset-customer-account/${id}`,
      method: 'POST',
      successText: 'Kontot återställdes',
    });
    if (err) return;

    navigate(`/customers/add-to-customer-portal/?id=${id}`);
  };

  const resetSpecificUsersPasswords = async () => {
    const { id } = parseQuery(location);
    const users = selectUsersToResetModal.users
      .filter((user) => user.selected)
      .map((user) => user.email);
    if (!users.length) {
      setSelectUsersToResetModal({
        ...selectUsersToResetModal,
        active: false,
      });
      dispatch({ type: 'ALERT', content: 'Inga kontaktpersoner valdes' });
      return;
    }
    const { err } = await request({
      state,
      dispatch,
      path: `portalCustomers/reset-customer-account-for-specific-emails/${id}`,
      method: 'POST',
      body: users,
      successText: 'Lösenorden återställdes',
    });
    if (err) return;

    getCustomer();
  };

  const createInitialPortalCustomer = async (id, customer) => {
    const { data, err } = await request({
      state,
      dispatch,
      path: 'portalCustomers/create-initial-portal-customer',
      method: 'POST',
      body: {
        customerId: id,
        customerName: customer.customerInformation.name,
      },
    });
    if (err) return;
    setCustomer(data);
  };

  const setBlockingStatusInit = () => {
    if (customer.suspended) return unBlockCustomer();
    else {
      setBlockCustomerModal(true);
    }
  };

  const blockCustomer = async () => {
    const { err } = await request({
      state,
      dispatch,
      path: `portalCustomers/block-customer/${customer.customerId}`,
      method: 'POST',
      successText: 'Kunden blockerades',
    });
    if (err) return;
    setBlockCustomerModal(false);
    getCustomer();
  };

  const unBlockCustomer = async () => {
    const { err } = await request({
      state,
      dispatch,
      path: `portalCustomers/unblock-customer/${customer.customerId}`,
      method: 'POST',
      successText: 'Blockeringen hävdes',
    });
    if (err) return;
    getCustomer();
  };

  const deleteAllFilesInit = () => {
    setDeleteAllFilesModal(true);
  };

  const deleteAllFiles = async () => {
    const { err } = await request({
      state,
      dispatch,
      isDoServer: true,
      successText: 'Filerna raderades',
      path: `admin-side-portal-customer-files/{token}/all-portal-files/${customer.customerId}`,
      method: 'DELETE',
    });
    if (err) return;
    setDeleteAllFilesModal(false);
    getCustomer();
  };

  const deleteFileInit = (file) => {
    let fileName = '';
    if (typeof file === 'string') {
      fileName = file;
    } else {
      fileName = file.name;
    }
    setDeleteFileModal({
      active: true,
      fileName,
      uuid: file.uuid,
    });
  };

  const deleteFile = async () => {
    const { id } = parseQuery(location);
    if (!id) return;

    const { err } = await request({
      state,
      dispatch,
      method: 'DELETE',
      path: `admin-side-portal-customer-files/{token}/delete-single-file/${id}/${deleteFileModal.fileName}?uuid=${deleteFileModal.uuid}`,
      successText: 'Filen raderades',
      isDoServer: true,
    });
    if (err) return;
    setDeleteFileModal({
      active: false,
      fileName: '',
      uuid: '',
    });
    getCustomer();
  };

  const getFiles = async () => {
    const { id } = parseQuery(location);
    if (!id) return;

    const { data, err } = await request({
      state,
      dispatch,
      path: `admin-side-portal-customer-files/{token}/files-list/${id}`,
      isDoServer: true,
    });
    if (err) return;
    setFilesList(data.localFiles);
    setFilesListS3(data.s3Files);
  };

  const uploadFiles = async (e) => {
    const fileInput = e.target;
    const { id } = parseQuery(location);
    if (!id) return;
    if (fileInput.files.length === 0) return;
    dispatch({ type: 'LOADING_PROGRESS', progress: 1, value: true });
    const files = fileInput.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const fileName = sanitizeFileNameForContentHeader(files[i].name);
      formData.append(fileName, files[i]);
    }

    const { err: doErr } = await progressRequest({
      state,
      dispatch,
      path: `admin-side-portal-customer-files/{token}/upload-files/${id}`,
      method: 'POST',
      body: formData,
      successText: 'Filerna laddades upp',
    });
    if (doErr) return;
    const { err: mongoErr } = await request({
      state,
      dispatch,
      method: 'POST',
      path: `portalCustomers/set-deletion-date/${id}`,
    });
    if (mongoErr) return;
    getCustomer();
    getFiles();
    fileInput.value = null;
  };

  const browseCustomerFiles = () => {
    setCustomerFilesModal({
      ...customerFilesModal,
      active: true,
    });
  };

  const copyProtocolsToCustomerPortal = async () => {
    const { err: doErr } = await request({
      state,
      dispatch,
      path: `admin-side-portal-customer-files/{token}/copy-protocol-files-to-customer-portal/${originalCustomer.customerInformation.name
        .split(' ')
        .join('_')}/${originalCustomer._id}`,
      isDoServer: true,
      method: 'POST',
      successText: 'Filerna lades till',
      body: customerFilesModal.selectedFiles,
    });
    if (doErr) return;
    setCustomerFilesModal({
      active: false,
      selectedFiles: [],
    });

    const { err: mongoErr } = await request({
      state,
      dispatch,
      path: `portalCustomers/set-deletion-date/${customer.customerId}`,
      method: 'POST',
    });
    if (mongoErr) return;
    getCustomer();
  };

  const downloadFile = async (file) => {
    if (typeof file === 'string') {
      window.open(
        `${do_url}admin-side-portal-customer-files/${state.token}/single-file/${originalCustomer._id}/${file}?isLocalFile=true`
      );
      return;
    }

    const { data: url } = await request({
      state,
      dispatch,
      path: `admin-side-portal-customer-files/{token}/single-file/${originalCustomer._id}/${file.uuid}`,
      isDoServer: true,
    });
    if (!url) return;
    window.open(url);
  };

  const handleSelectFromMediaLib = async (files) => {
    if (!files.length) return;
    const errors = [];
    dispatch({ type: 'LOADING', value: true });
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { err: doErr } = await request({
        state,
        dispatch,
        method: 'POST',
        path: `admin-side-portal-customer-files/{token}/copy-file-from-media-lib/${originalCustomer._id}`,
        body: { file },
        isDoServer: true,
        skipLoader: true,
      });
      if (doErr) errors.push(file.name);
    }

    if (errors.length) {
      dispatch({ type: 'ALERT', content: 'Vissa filer kunde inte laddas upp' });
      console.log('Errors uploading to customer portsl:', errors);
    }
    dispatch({ type: 'ALERT', content: 'Filerna laddades upp' });
    getFiles();

    const { err: mongoErr } = await request({
      state,
      dispatch,
      path: `portalCustomers/set-deletion-date/${customer.customerId}`,
      method: 'POST',
    });

    if (mongoErr) return;
    getCustomer();
  };

  useEffect(() => {
    dispatch({ type: 'LOADING', value: true });
    if (state.token) {
      getCustomer();
    }
    // eslint-disable-next-line
  }, [state.token]);

  return (
    <Layout>
      {customer && (
        <>
          <h1 className="page-header">Kundfiler</h1>
          <Breadcrumbs
            crumbs={[
              { to: '/home', p: 'Hem' },
              { to: '/customers', p: 'Kunder' },
              {
                to: `/customers/single/?id=${customer?.customerId}`,
                p: customer?.customerName,
              },
              {
                p: 'Hantera kundfiler',
              },
            ]}
          />
          <StyledCustomerPortalPage>
            <div className="single-customer-inner">
              <div className="single-customer-left-content">
                <Card>
                  <div className="customer-card-header">
                    <h3>Kundfiler</h3>
                    <div className="customer-card-header-right-content">
                      <Button
                        noBg
                        onClick={() => {
                          const fileInput = document.getElementById(
                            'customerPortalFileInput'
                          );
                          if (!fileInput) return;
                          fileInput.click();
                        }}
                      >
                        Ladda upp filer
                      </Button>
                      <input
                        type="file"
                        multiple
                        id="customerPortalFileInput"
                        onChange={uploadFiles}
                      />
                      <FileUploadButton
                        context={{
                          customer: customer?.customerId,
                        }}
                        multiple
                        buttonText={'Välj från filer'}
                        onSelect={handleSelectFromMediaLib}
                      />
                    </div>
                  </div>
                  <div className="customer-files-container">
                    {filesList.map((file, i) => (
                      <div className="single-customer-file" key={file + i}>
                        <Card inner>
                          <p>{file}</p>
                        </Card>
                        <DotsDropdown
                          content={
                            <>
                              <Button small onClick={() => downloadFile(file)}>
                                Ladda ned
                              </Button>
                              <Button
                                small
                                look="danger"
                                onClick={() => deleteFileInit(file)}
                              >
                                Radera
                              </Button>
                            </>
                          }
                        />
                      </div>
                    ))}
                    {filesListS3.map((file, i) => (
                      <div className="single-customer-file" key={file.uuid}>
                        <Card inner>
                          <p>{file.name}</p>
                        </Card>
                        <DotsDropdown
                          content={
                            <>
                              <Button small onClick={() => downloadFile(file)}>
                                Ladda ned
                              </Button>
                              <Button
                                small
                                look="danger"
                                onClick={() => deleteFileInit(file)}
                              >
                                Radera
                              </Button>
                            </>
                          }
                        />
                      </div>
                    ))}
                  </div>
                </Card>
              </div>
              <div className="single-customer-right-content">
                <Card>
                  <div className="customer-card-header">
                    <h3>Åtgärder</h3>
                  </div>
                  <div className="actions-container">
                    <div className="single-action">
                      <hr />
                      <Button noBg onClick={browseCustomerFiles}>
                        Lägg till protokollfiler
                      </Button>
                      <hr />
                    </div>
                    <div className="single-action">
                      <Button
                        noBg
                        onClick={() => {
                          navigate(
                            `/customers/add-to-customer-portal/?id=${customer.customerId}`,
                            { state: { isLinkFromHandleFiles: true } }
                          );
                        }}
                      >
                        Skicka länk
                      </Button>
                      <hr />
                    </div>
                    <div className="single-action">
                      <Button
                        noBg
                        look={customer.suspended ? '' : 'danger'}
                        onClick={setBlockingStatusInit}
                      >
                        {customer.suspended
                          ? 'Häv blockering'
                          : 'Blockera inloggning'}
                      </Button>
                      <hr />
                    </div>
                    <div className="single-action">
                      <Button
                        look="danger"
                        noBg
                        onClick={() => setResetAccountModal(true)}
                      >
                        Återställ alla lösenord
                      </Button>
                      <hr />
                    </div>
                    <div className="single-action">
                      <Button
                        look="danger"
                        noBg
                        onClick={() =>
                          setSelectUsersToResetModal({
                            ...selectUsersToResetModal,
                            active: true,
                          })
                        }
                      >
                        Återställ vissa lösenord
                      </Button>
                      <hr />
                    </div>
                    <div className="single-action">
                      <Button look="danger" noBg onClick={deleteAllFilesInit}>
                        Radera alla filer
                      </Button>
                      <hr />
                    </div>
                  </div>
                </Card>
                <Card>
                  <div className="customer-card-header">
                    <h3>Information</h3>
                  </div>
                  <InformationGrid pairs={informationGridData} />
                </Card>
              </div>
            </div>
            <OverlayBox
              active={customerFilesModal.active}
              close={() =>
                setCustomerFilesModal({ ...customerFilesModal, active: false })
              }
              header="Lägg till protokollfiler"
            >
              <CustomerFilesBrowser
                customer={originalCustomer}
                state={state}
                dispatch={dispatch}
                files={customerFilesModal.selectedFiles}
                commitFiles={copyProtocolsToCustomerPortal}
                setFiles={(e) =>
                  setCustomerFilesModal({
                    ...customerFilesModal,
                    selectedFiles: e,
                  })
                }
              />
            </OverlayBox>
            <OverlayBox
              header="Radera fil?"
              active={deleteFileModal.active}
              close={() =>
                setDeleteFileModal({
                  ...deleteFileModal,
                  active: false,
                  uuid: '',
                })
              }
            >
              <p>Är du säker på att du vill radera filen?</p>
              <Button
                small
                onClick={() =>
                  setDeleteFileModal({
                    ...deleteFileModal,
                    active: false,
                    uuid: '',
                  })
                }
              >
                Avbryt
              </Button>
              <Button small look="danger" onClick={deleteFile}>
                Radera
              </Button>
            </OverlayBox>
            <OverlayBox
              header="Blockera kund?"
              active={blockCustomerModal}
              close={() => setBlockCustomerModal(false)}
            >
              <p>
                Är du säker på att du vill blockera inloggning?
                <br />
                Kunden kommer inte att kunna logga in förrän blockeringen hävs.
              </p>
              <Button small onClick={() => setBlockCustomerModal(false)}>
                Avbryt
              </Button>
              <Button small look="danger" onClick={blockCustomer}>
                Blockera
              </Button>
            </OverlayBox>
            <OverlayBox
              header="Radera alla kundens filer"
              active={deleteAllFilesModal}
              close={() => setDeleteAllFilesModal(false)}
            >
              <p>Är du säker på att du vill radera alla kundens filer?</p>
              <Button small onClick={() => setDeleteAllFilesModal(false)}>
                Avbryt
              </Button>
              <Button small look="danger" onClick={deleteAllFiles}>
                Radera alla
              </Button>
            </OverlayBox>
            <OverlayBox
              header="Återställ alla konton"
              active={resetAccountModal}
              close={() => setResetAccountModal(false)}
            >
              <p>
                Är du säker på att du vill återställa kontakpersoners lösenord?
                <br />
                Du kommer att behöva skicka en ny länk till varje kontaktperson
                för att de ska kunna skapa ett nytt lösenord.
              </p>
              <Button small onClick={() => setResetAccountModal(false)}>
                Avbryt
              </Button>
              <Button small look="danger" onClick={resetCustomerAccount}>
                Återställ
              </Button>
            </OverlayBox>
            <OverlayBox
              active={selectUsersToResetModal.active}
              close={() =>
                setSelectUsersToResetModal({
                  ...selectUsersToResetModal,
                  active: false,
                })
              }
              header="Välj kontaktpersoner att återställa"
            >
              <p>
                Välj vilka kontaktpersoners lösenord du vill återställa.
                <br />
                Du kommer att behöva skicka en ny länk till valda
                kontaktpersoner för att de ska kunna skapa ett nytt lösenord.
              </p>
              <div className="users-to-reset-list">
                {selectUsersToResetModal.users.map((user, i) => (
                  <div
                    className="users-to-reset-item"
                    key={`usersToReset_${user.email}_${i}`}
                  >
                    <input
                      type="checkbox"
                      checked={user.selected}
                      onChange={() => {
                        const tempUsers = selectUsersToResetModal.users;
                        tempUsers[i].selected = !tempUsers[i].selected;
                        setSelectUsersToResetModal({
                          ...selectUsersToResetModal,
                          users: tempUsers,
                        });
                      }}
                    />
                    <p>{user.email}</p>
                  </div>
                ))}
              </div>
              <Button
                small
                onClick={() =>
                  setSelectUsersToResetModal({
                    ...selectUsersToResetModal,
                    active: false,
                  })
                }
              >
                Avbryt
              </Button>
              <Button look="danger" small onClick={resetSpecificUsersPasswords}>
                Återställ
              </Button>
            </OverlayBox>
          </StyledCustomerPortalPage>
        </>
      )}
    </Layout>
  );
};

export default CustomerPortalPage;
